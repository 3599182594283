<template>
  <div class="container-main-content">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Đăng nhập</h4>
        <button
          type="button"
          class="btn btn-primary btn-in-login w-100 mb-3"
          @click="handleLoginByEnterprise"
        >
          <span>Bạn là chủ doanh nghiệp</span>
          <span><img src="../../assets/images/arrow-right.svg" /></span>
        </button>
        <button
          type="button"
          class="btn btn-primary btn-in-login w-100 mb-3"
          @click="handleLoginByStaff"
        >
          <span>Bạn là nhân viên</span>
          <span><img src="../../assets/images/arrow-right.svg" /></span>
        </button>

        <div
          style="
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
          "
        >
          <hr class="w-100" />
          <span>hoặc</span>
          <hr class="w-100" />
        </div>

        <button
          type="button"
          class="btn btn-in-login w-100 mb-5"
          style="
            justify-content: center;
            gap: 10px;
            color: white;
            background-color: #eb9422;
          "
          @click="handleLoginByMPOS"
        >
          <span><img src="../../assets/images/logo-mpos.png" /></span>
          <span>Đăng nhập bằng MPOS</span>
        </button>

        <p class="content-inner__body-start f14 mt-4">
          Chưa có tài khoản?
          <a href="#" class="link-primary-color" @click.prevent="handleSignUp">
            Bắt đầu ngay
          </a>
        </p>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { MPOS_CALLBACK_URI, MPOS_ROOT_URI } from "../../.env";

const normalized = (str) => str.trim().toLowerCase();

export default {
  name: "Login",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      callbackCodeMappings: {
        mobile: {
          nextshop: "NEXTSHOPAPP",
          next360: "NEXT360APP",
        },
        web: {
          nextshop: "NEXTSHOP",
          next360: "OAUTH_HOME",
        },
      },
    };
  },
  methods: {
    handleSignUp() {
      this.$router.push({ path: `/signup${this.currentQueries}` });
    },
    handleLoginByEnterprise() {
      this.$router.push({ path: `/login-by-enterprise${this.currentQueries}` });
    },
    handleLoginByStaff() {
      this.$router.push({ path: `/login-by-staff${this.currentQueries}` });
    },

    handleLoginByMPOS() {
      const queries = this.currentQueries
        .slice(1)
        .split("&")
        .map((elem) => {
          const idx = elem.indexOf("=");
          return { key: elem.slice(0, idx), value: elem.slice(idx + 1) };
        });

      const mobileQuery = queries.find(
        (elem) => normalized(elem.key) === "mobile"
      );
      const usingMobile =
        mobileQuery &&
        normalized(decodeURIComponent(mobileQuery.value)) === "true";

      const redirectAppQuery = queries.find(
        (elem) => normalized(elem.key) === "redirect-app"
      );
      const directToNextshop =
        redirectAppQuery &&
        normalized(decodeURIComponent(redirectAppQuery.value)) === "nextshop";

      const browserType = usingMobile ? "mobile" : "web";
      const redirectApp = directToNextshop ? "nextshop" : "next360";
      const code = this.callbackCodeMappings[browserType][redirectApp];
      // console.log("$ browserType:", browserType);
      // console.log("$ redirectApp:", redirectApp);
      // console.log("$ redirectLink:", redirectUri);
      let additionalQuery = "";
      if (usingMobile) additionalQuery = "&mobile=true";
      additionalQuery = additionalQuery += `&redirect-app=${redirectApp}`;
      const redirectUri =
        MPOS_ROOT_URI +
        encodeURIComponent(
          MPOS_CALLBACK_URI + `?code=${code}` + additionalQuery
        );
      window.location.href = redirectUri;
    },
  },
};
</script>
